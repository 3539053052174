import React, { useEffect, useState } from 'react';
import {Hero, About, Contact} from '../components';
import './full.scss';

const headlines = [
    "Hello World! 0",
    "Hello React! 1",
    "Hello Hello 2",
    "Let's continue with the next lesson!  3"
]

const pages = [ <Hero />, < About />, <Contact /> ];

const nextKeys = [
    { key: "ArrowDown", charCode: 0, keyCode: 40 },
    { key: "ArrowRight", charCode: 0, keyCode: 39 },
    { key: " ", charCode: 0, keyCode: 32 },
];
const prevKeys = [
    { key: "ArrowUp", charCode: 0, keyCode: 38 },
    { key: "ArrowLeft", charCode: 0, keyCode: 37 }
]




const  Full = () => {

    let active = 0;
    let themes = [];
    let intervals = [];
    let keyPressed = false;
    let lastKnownScrollPosition = 0;
    let ticking = false;


const [ state, setState ] = useState({
    active: 0,
    themes: [],
    intervals: [],
    keyPressed: false
});




function handleKeyDown(  e ){
    if( e.repeat ) return;
    setState( prev => ({...prev, keyPressed: true }) );
    let next = nextKeys.filter( key => key.keyCode === e.keyCode );
    let prev = prevKeys.filter( key => key.keyCode === e.keyCode );
    if( next.length ) console.log('Next');
    if( prev.length) console.log( "Previious")
} 

function handleKeyUp( e ){
    setState( prev => ({ ...prev, keyPressed: false }) );
}

function handleTouchStart( e ){
    e.preventDefault();
    console.log( e )
}
function handleTouchEnd( e ){
    e.preventDefault();

    console.log( e )
}
function handleTouchCancel( e ){
    e.preventDefault();

    console.log( e )
}
function handleTouchMove( e ){
    e.preventDefault();

    console.log( e )
}

function navToNext( ){
    
}



function handleScroll( e ){

    if( typeof window === 'undefined' ) return;
    let currPosition = window.scrollY;

    const indicator = document.querySelector('.indicator');
    console.log("These are the themes");

    setState( state => ( {...state, scrolling: true} ) );


    if( ! ticking ){
        ticking = true;
        window.requestAnimationFrame( function(){
            // calculations here
            const indicatorBottom = indicator.getBoundingClientRect().bottom; 
            const indicatorTop = indicator.getBoundingClientRect().top; 
            const windowTop = window.scrollY;
            const windowBottom = windowTop + window.innerHeight;

            let i = 0;
            // while( windowBottom <= sectionIntervals[ i ] ){
            //     console.log(sectionIntervals[i])
            //     i++;
            // }

            // let theme = themes[ i - 1];
            // console.log({ theme });
            if( currPosition < lastKnownScrollPosition ){
                console.log('scroll up');
            } else {
                console.log('scroll down');
            }
            ticking = false;
            lastKnownScrollPosition = currPosition;
        })


    }

    // console.log( { lastKnownScrollPosition } );
}

const eventListeners = {

    registerEventListeners: function(){
        document.addEventListener( 'keydown', handleKeyDown );
        document.addEventListener( 'keyup', handleKeyUp );
        document.addEventListener('touchstart', handleTouchStart, { passive:true } );
        document.addEventListener('touchend', handleTouchEnd, { passive:true } );
        document.addEventListener('touchcancel', handleTouchCancel, { passive:true} );
        document.addEventListener('touchmove', handleTouchMove, { passive:true } );
        document.addEventListener('scroll', handleScroll, { passive:true } );
    },
    
    unregisterEventListeners: function(){
        document.removeEventListener( 'keydown', handleKeyDown );
        document.removeEventListener( 'keyup', handleKeyUp );
        document.removeEventListener( 'touchstart', handleTouchStart );
        document.removeEventListener( 'touchend', handleTouchEnd );
        document.removeEventListener( 'touchcancel', handleTouchCancel );
        document.removeEventListener( 'touchmove', handleTouchMove );
        document.removeEventListener('scroll', handleScroll);
    }

}

useEffect( () => {

    const sections = document.querySelectorAll('.page');
    const sectionBottoms = [];
    const sectionThemes = [];
    for( let sec of sections ){

        const { bottom } = sec.getBoundingClientRect();
        const classList = sec.classList;
        classList.contains('light') ? sectionThemes.push('light') : sectionThemes.push('dark');
        sectionBottoms.push( bottom );
    }

    themes = sectionThemes;
    intervals = sectionBottoms;

    setState( prev => ( { ...prev, intervals: sectionBottoms, themes: sectionThemes } ));

  
    const { registerEventListeners, unregisterEventListeners } = eventListeners;
    registerEventListeners();

    return function(){ unregisterEventListeners(); }



}, []);


    return (
        <div className="pager">
            <div className="indicator"></div>
            { headlines.map( ( el, index ) => {

                
                return ( <div className={ `page ${index % 2 === 0 ? 'dark' : 'light' } ${index}` } key={index}> { state.keyPressed.toString() } || { state.intervals.toString() } </div> )

            })}
        </div>
    )
};

export default Full;